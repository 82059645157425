@import '../../../styles//variables';

.student-item {
  display: flex;
  align-items: center;

  &__avatar {
    margin-right: 8px;
  }

  &__wrap {
    width: 35%;
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 1.333rem;
    line-height: 2.333rem;
    color: $gray-cod;
  }

  &__stats-values {
    width: 51%;
  }

  &__stats-item {
    width: 32%;
    align-items: baseline;
  }

  &__stats-name,
  &__stats-subname {
    font-size: 1.1666rem; 
  }

  &__stats-count {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  &__btn {
    margin-left: auto;
  }
}