@import '../../../styles/variables';

.mini-footer {
  height: 50px;
  margin: 20px auto 20px;

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
  }

  &__part {
    list-style: none;
    font-size: 11px;
 
    &:first-child {
      &:before {
        display: none;
      }
    }

    &::before {
      content: '\2022';
      margin: 2px;
    }
  }

  &__mail {
    text-decoration: none !important;
    color: $black;
    }

  @media (min-width: $min-mobile) and (max-width: $mini) {
    &__content {
      flex-direction: column;
      list-style: none;
    }

    &__part {
      margin-left: 0;
      margin-bottom: 1px;
      &:before {
        display: none;
      }
    }
  }
}
