@import '../../../styles/variables';

.search-block {
  box-sizing: border-box;
  border: 8px solid $gray-wild;
  display: flex;
  position: relative;

  &__form {
    box-sizing: border-box;
    width: 100%;
  }

  &__input {
    box-sizing: border-box;
    padding: 12px 8px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    line-height: 24px;
  }

  &__btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
