@import '../../../styles/variables';


.range-slider {
  padding: 0 36px;

  &__labels {
    display: flex;
    width: calc(100% + 5px);
    justify-content: space-between;
  }

  &__label-item {
    font-size: 24px;
    line-height: 28px;
    color: $gray-alto;

    &_active {
      color: $black;
    }
  }

  .rangeslider {
    position: relative;
    background-color: $gray-alto;
    box-shadow: none;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: -36px;
      width: 36px;
      height: 2px;
      background-color: $black;
    }

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      right: -36px;
      width: 36px;
      height: 2px;
      top: 0;
      background-color: $gray-alto;
    }
  }

  .rangeslider-horizontal {
    height: 2px;
  }

  .rangeslider__handle {
    outline: none;
    box-shadow: none;
    width: 12px;
    height: 12px;
    background-color: $black;
    border: none;
  }

  .rangeslider__handle-tooltip {
    display: none;
  }

  .rangeslider-horizontal .rangeslider__handle:after {
    display: none;
  }

  .rangeslider__labels .rangeslider__label-item {
    top: -60px;
    font-size: 24px;
    line-height: 28px;
    color: $gray-alto;
  }

  .rangeslider__fill {
    background-color: $black;
  }
}
