@import '../../../../../styles/variables';

.assignment-not-correct {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 64px;

  &__title {
    color: $black;
    font-size: 20px;
    line-height: 23px;
  }

  &__title-failed {
    color: $red-pomegranate;
    font-size: 24px;
    line-height: 32px;
    margin-top: 17px;
  }

  &__attempt {
    display: flex;
    flex-direction: column;
  }

  &__tries {
    background-color: $aqua-spring;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    margin: 16px 64px 0 0;
    font-size: 16px;
    line-height: 24px;

    &-count {
      margin: 8px 0 0 8px;
    }

    &-total {
      margin: 8px 0 8px 8px;
    }

    &-not-attempt {
      color: $red-contessa;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 8px 8px;
    }
  }

  &__start {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 9px 64px 0 0;
  }
}
