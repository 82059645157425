@import '../../../styles/variables';

.avatar-with-name {
  display: flex;
  align-items: center;

  &__name {
    font-size: 16px;
    margin-left: 10px;
  }
}
