@import '../../../../../styles/variables';

.question-open {
  margin-top: 16px;

  &__editor {
    color: $gray-mine-shaft;
    white-space: pre-line;
    flex: 1;
  }

  &__author {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    outline: none;

    &-profile {
      display: flex;
      flex-direction: row;
      align-self: center;

      &-image {
        align-self: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 3px;
        object-fit: cover;
      }

      &-name {
        font-size: 16px;
        line-height: 24px;
        color: $black;
        margin-left: 16px;
        margin-top: 3px;
      }
    }

    &-date {
      align-self: center;
      font-size: 12px;
      line-height: 20px;
      color: $gray-dove;
    }
  }

  &__message {
    max-width: 574px;
    font-size: 16px;
    line-height: 24px;
    margin-left: 58px;
    color: $black;

    &-delete {
      display: flex;
      justify-content: flex-end;

      &_button {
        border: none;
        color: white;
        background-color: $red-punch;
        padding: 5px 10px;
        border-radius: 3px;
        cursor: pointer;
      }
    }

    &-text {
      margin-top: 16px;
      overflow: auto;

      &_new {
        color: $blue-shakespeare;
      }
    }

    &-attache {
      display: flex;
      flex-direction: column;
      margin-top: 32px;

      &-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: $blue-shakespeare;
      }

      &-file {
        border: none;
        background-color: transparent;
        outline: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: $black;
        margin-top: 10px;
        text-decoration: none;
        width: fit-content;
        cursor: pointer;
      }
    }

  }

  &__answer {
    position: relative;
    width: 100%;
    height: 64px;
    background-color: $gray-wild;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    outline: none;
    cursor: pointer;

    @media (max-width: $mobile) {
      width: 98%;
      border-right: 10px solid $gray-wild;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -20px;
      left: 44px;
      border: 10px solid transparent;
      border-bottom: 10px solid $gray-wild;
    }

    &-icon {
      align-self: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-left: 8px;
      object-fit: cover;
    }
    &-text {
      align-self: center;
      width: 89%;
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      color: $gray-gull;
      background-color: $light;
      margin-left: 16px;
      &-tutor {
        padding: 12px 8px;
      }
    }
  }
}
