.simple-layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
  }
}
