@import '../../styles/variables';

.organisations {
  
  .add-user {
    margin-left: auto;
  }

  &__header,
  &__copyright,
  &__wrap {
    padding: 24px 50px;
  }

  &__table {
    min-width: 100%;
    display: flex;

    background-color: $light;
    border-top: 1px solid $gray-alto;
    border-bottom: 1px solid $gray-alto;
  }

  &__list {
    width: 100%;

    .admin-item {
      &:after {
        content: '';
        position: absolute;
        height: 1px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $gray-alto;
      }
    }
  }

  &__item {
    position: relative;
    padding: 0 25px;
    min-height: 50px;
    box-sizing: border-box;
  }
  
}