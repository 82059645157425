.adding-new-student-form {
  width: 640px;
  padding-top: 30px;

  &__content {
    width: 70%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }


  &__field-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .input-field {
      width: 48%;
    }
  }

  &__submit {
    height: 30px;
  }

  &__btn-cancel {
    height: 30px;
    margin-right: 10px;
  }

  &__buttons {
    padding: 15px 0 15px 0;
    left: 20%;

    position: relative;
  }
}

