@import '../../../../../styles/variables';

.assignment-not-done {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 64px;

  &__title {
    color: $black;
    font-size: 20px;
    line-height: 23px;
  }

  &__attempt {
    display: flex;
    flex-direction: column;
  }

  &__tries {
    background-color: $aqua-spring;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 64px;
    margin: 16px 64px 0 0;
    font-size: 16px;
    line-height: 24px;

    &-count {
      margin: 8px 0 0 8px;
    }

    &-total {
      margin: 0 0 8px 8px;
    }
  }

  &__start {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 64px;
    margin-top: 9px;
  }
}
