@import './variables';


html {
  font-size: $font-size;
}

body {
  margin: 0;
  padding: 0;
  font-size: $font-size;
  line-height: $font-size * 1.25;
  font-weight: $regular;
  font-family: $font-family;
}

// extends
%pseudo-element-line {
  content: '';
  position: absolute;
  height: 18px;
  width: 2px;
}
