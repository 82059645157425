@import '../../../styles/variables';

.veil {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($black, 0.2);
}

.popup {
  position: absolute;
  top: 136px;

  border: 8px solid $silver;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: 1;
  background-color: $light;


  &__btn-close {
    right: 10px;
    top: 10px;
  }

}
