@import '../../../styles/variables';

.dialog-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($black, 0.2);

  &__content-block {
    max-width: 614px;
    max-height: 410px;
    min-height: 410px;
    padding: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $light;
    box-sizing: border-box;

    @media (max-width: $mobile) {
      max-width: calc(100vw - 30px);
      padding: 15px 50px;
      min-height: 310px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: $gray-mine-shaft;
    text-align: center;
    margin-bottom: 30px;
  }

  &__button-block {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  &__btn {
    width: 96px;
    height: 32px;
    border: none;
    border-radius: 4px;
    color: $light;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 24px;
    text-align: center;
    vertical-align: center;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
    outline: none;

    &:first-child {
      margin-left: 0;
    }

    &_yes {
      background-color: $gray-mine-shaft;
    }

    &_no {
      background-color: $grey-middle;
    }
  }

}


