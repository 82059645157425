@import '../../../styles/variables';

.edit-profile-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__label {
    font-size: 14px;
    line-height: 16px;
    color: $gray-silver;
    margin-bottom: 10px;

    &_with-info {
      margin-bottom: 7px;
    }
  }

  &__info {
    font-size: 14px;
    line-height: 18px;
    color: $black;
    margin-bottom: 24px;
  }

  &__input-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__input {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    border: none;
    padding: 12px 8px;
    width: 100%;

    @media (max-width: $mobile) {
      width: 80%;
    }

    &_is-error {
      color: $red-flamingo;
      border: 1px solid $red-flamingo;
    }
  }

  &__add-btn {
    font-size: 13px;
    line-height: 20px;
    color: $gray-mine-shaft;
    border: none;
    padding: 0;
    margin-bottom: 26px;
    cursor: pointer;
  }

  &__wrap-strung {
    display: flex;
  }

  &__name-label {
    font-size: 14px;
    line-height: 20px;
    color: $gray-boulder;
  }

  &__name-info {
    font-size: 14px;
    line-height: 20px;
    color: $gray-mine-shaft;
    margin-bottom: 8px;
  }

  &__name-field-value {
    font-size: 20px;
    line-height: 28px;
    color: $gray-mine-shaft;
    margin-bottom: 24px;
  }

  &__separator {
    border: none;
    width: 100%;
    border-top: 1px solid $gray-mercury;
    margin-bottom: 24px;
  }

  &__language-select {
    width: 100%;
    margin-bottom: 24px;
  }

  &__checkbox {
    margin-bottom: 14px;
  }

  &__save-btn {
    padding: 8px 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $light;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px;
    background-color: $blue-shakespeare;
    border: none;
    border-radius: 4px;
    margin-top: 28px;
    align-self: flex-end;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__error {
    margin: 0;
    padding: 0;
    position: relative;
    top: -10px;
    color: $red-flamingo;
  }

  &__input-wrapper {
    width: 100%;
    position: relative;
  }

  &__close-btn {
    position: absolute;
    right: -18px;
    top: 5px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
