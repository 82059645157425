@import '../../../styles/variables';

.course-content {
  max-width: 672px;
  margin: 0 auto;
  margin-top: 64px;

  padding: 0 16px;
  display: flex;
  flex-direction: column;
}
