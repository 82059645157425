@import '../../../styles/variables';

.student-course-card-string {
  box-sizing: border-box;
  display: flex;
  border: 1px solid $gray-mercury;

  &__img {
    width: 58px;
    height: 72px;
    object-fit: cover;
    object-position: center center;

    @media (max-width: $mobile) {
      height: 100%;
      width: auto;
      max-height: 110px;
      max-width: 90px;
    }
  }

  &__info {
    display: flex;
    padding: 8px 24px 16px;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;

    @media (max-width: $mobile) {
      flex-direction: column;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    color: $gray-mine-shaft;

    @media (max-width: $mobile) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__btn {
    margin-top: 16px;
    align-self: flex-end;
    background-color: $green-sushi-dark;
    border: none;
    border-radius: 4px;
    color: $light;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
    text-decoration: none;

    @media (max-width: $mobile) {
      align-self: flex-start;
    }
  }


}
