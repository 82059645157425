@import '../../../styles/variables';

.upload-form {
  &__button {
    &-upload, &-click {
      display: flex;
      flex-direction: row;
    }

    &-upload {
      max-width: 300px;
      color: $blue-icon;
      font-weight: 500;
      font-size: 16px;
      margin-top: 15px;
      align-items: center;
      cursor: pointer;
    }
  }
}
