@import '../../../styles/variables';

.link {
  text-decoration: none;

  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 466px;
    height: 75px;
    background-color: $grey-org;
    border: 1px solid $grey-org-border;
    border-radius: 4px;
    margin-top: 8px;
  }

  &__icon-text {
    display: flex;
    align-items: center;
    margin-left: 19px;
    width: 75%;
    overflow: hidden;
  }

  &__icon-block {
    width: 18px;
    margin-right: 10px;
  }

  &__icon {
    max-width: 18px;
    max-height: 18px;
  }

  &__text {
    font-family: $font-family;
    font-size: 18px;
    line-height: 48px;
    color: $black;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__icon-arrow {
    width: 100%;
    max-width: 18px;
    max-height: 18px;
    fill: $black;
    margin-right: 26px;
  }

  @media (min-width: $min-mobile) and (max-width: $mobile) {
    &__input {
      width: 328px;
      height: 58px;
    }

    &__icon-arrow {
      max-width: 16px;
      max-height: 16px;
      margin-right: 24px;
    }

    &__text {
      line-height: 24px;
    }
  }
}
