// screens
$mini: 480px;
$min-mobile: 360px;
$mobile: 768px;
$md: 1024px;
$lg: 1366px;

// fonts
$regular: 400;

$font-size: 12px;
$font-family: 'Roboto', sans-serif;

// colors
$light: #ffffff;
$black: #000000;
$silver: #cccccc;

$black-text: #1e4661;
$aqua-spring: #eaf5f9;
$grey-org: #f4f4f4;
$grey-org-border: #d7d7d7;
$gray-mine-shaft: #212121;
$gray-cod: #1A1A1A;
$gray-mine: #303030;
$gray-tundora: #424242;
$gray-dove-dark: #616161;
$gray-dove: #666666;
$gray-gull: #9daeb6;
$gray-boulder: #757575;
$gray-quartz: #9595918c;
$gray-dusty: #959595;
$gray-dusty-light: #999999;
$gray-silver: #bdbdbd;
$gray-silver-chalice: #9E9E9E;
$gray-silver-dark: #c7c7c7;
$gray-alabaster: #fafafa;
$gray-gallery: #eeeeee;
$gray-alto: #d8d8d8;
$gray-wild: #f5f5f5;
$gray-concrete: #F2F2F2;
$gray-alto: #e0e0e0;
$gray-mercury: #e8e8e8;
$gray-horizon: #57809b;
$gray-gallery-background: #f0f0f0;
$gray-scorpion: #606060;
$gray-button: #D4D4D4;
$red-flamingo: #f44d41;
$red-pomegranate: #F44336;
$red-error: #f312022b;
$red-contessa: #C86B6B;
$red-punch: #DC3C31;
$brown-fuzzy: #C34A4A;

$blue-shakespeare: #33A0CC;
$blue-viking: #60B8DC;
$blue-seagull: #8AD1F0;
$blue-mabel: #D7F3FF;
$blue-dodger: #2684ff6b;
$french-pass: #BDECFE;

$hint-of-green: #E5FFE5;
$green-sushi: #8BC34A;
$green-sushi-dark: #76A53E;
$green-vida-loca: #5E991A;

$barley-white: #FFF4CA;
$error-red: #991A1A;
$grey-middle: #969696;
$certification-color-text: #808080;
$big-points-color: #16668A;
$success-title-color: #85b870;
$error-text-color: #D27979;
$blue-icon: #2AA6C3;

