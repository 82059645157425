@import '../../../../styles/variables';

.course-info-section {
  font-family: $font-family;
  font-size: 16px;
  line-height: 22px;
  color: rgba($black, 0.8);

  &__title {
    font-size: 22px;
    line-height: 24px;
    font-weight: bold;
    color: $black;
  }

  h4 {
    font-weight: bold;
    color: $black;
  }
}
