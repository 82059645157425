.message-popup {
  padding: 30px 0;

  width: 592px;

  font-size: 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;


  &__message {
    padding-bottom: 20px;
  }

  &__buttons {
    padding-top: 20px;
  }

  &__submit {
    font-size: 1.10rem;
    height: 30px;
  }
}
