@import '../../../styles/variables';

.student-course-card {
  box-sizing: border-box;
  display: flex;
  border: 1px solid $gray-mercury;
  height: 300px;

  @media (max-width: $mobile) {
    flex-direction: column;
    min-height: 100%;
    max-height: 100%;
  }

  &__img {
    max-width: 35%;
    object-fit: cover;
    object-position: center center;
    min-width: 180px;

    @media (max-width: $mobile) {
      max-width: 100%;
      min-width: 100%;
      max-height: 240px;
    }

  }

  &__info-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 18px;
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    color: $gray-mine-shaft;
    margin-bottom: 16px;
  }

  &__progress-block {
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__progress-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 4px;
  }

  &__progress-name {
    color: $gray-boulder;
    font-size: 14px;
    line-height: 24px;
  }

  &__progress-value {
    color: $green-sushi;
    font-size: 18px;
    line-height: 24px;
    margin-left: auto;
    justify-self: flex-end;
  }

  &__progress-bar {
    width: 100%;
  }

  &__btn {
    margin-top: 16px;
    align-self: flex-end;
    background-color: $black;
    border: none;
    border-radius: 4px;
    color: $light;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
    text-decoration: none;
  }


}
