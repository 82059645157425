@import '../../../styles/variables';

.table-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    color: $gray-dove-dark;
    font-size: 1.1666rem;
    line-height: 2rem;
  }
}

.table-content {
  min-width: 100%;
  display: flex;

  background-color: $light;
  border-top: 1px solid $gray-alto;
  border-bottom: 1px solid $gray-alto;

  &__list {
    width: 100%;
  }

  &__item {
    position: relative;
    padding: 0 25px;
    padding-right: 50px;
    min-height: 50px;
    box-sizing: border-box;

    &:after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $gray-alto;
   }
  }
}
