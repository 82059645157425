@import '../../../styles/variables';

.communication {
  margin: 0 auto;
  max-width: 632px;
  margin-top: 64px;
  margin-bottom: 64px;

  @media (max-width: $mobile) {
   max-width: 100%;
   margin-right: 10px;
   margin-left: 10px;
  }

  &__search-answer {
    border: 4px solid $gray-gallery;
  }

  &__search {
    box-sizing: border-box;
    border: none;
    display: flex;
    position: relative;

    &-form {
      box-sizing: border-box;
      width: 100%;
    }

    &-input {
      box-sizing: border-box;
      padding: 12px 8px;
      width: 95%;
      outline: none;
      border: none;
      font-size: 20px;
      line-height: 28px;
      color: $black;
    }

    &-btn {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      outline: none;
      border: none;
      background-color: transparent;
    }

    &-heading {
      font-size: 16px;
      line-height: 24px;
      color: $black
    }

    &-message {
      font-size: 14px;
      line-height: 24px;
      color: $gray-dove;
    }
  }

  &__title {
    height: 28px;
    overflow: hidden;
    font-size: 20px;
    line-height: 28px;
    color: $black;
  }

  &__line {
    height: 1px;
    width: 100%;
    margin-bottom: 25px;
    border: none;
    background: $gray-gallery;
  }

  &__question {
    width: 100%;
    height: 64px;
    margin-top: 5px;
    background-color: $gray-wild;
    display: flex;
    flex-direction: row;
    outline: none;

    &-icon {
      align-self: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-left: 8px;
      object-fit: cover;
    }
    &-text {
      align-self: center;
      width: 89%;
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      color: $gray-gull;
      background-color: $light;
      margin-left: 16px;
      border-right: 10px solid $gray-wild;

      @media (max-width: $min-mobile) {
        font-size: 14px;
      }

      &-tutor {
        padding: 12px 8px;
      }
    }
  }

  &__question-field {
    background-color: $light;
  }

  &__message {
    background-color: $gray-alabaster;
    height: 48px;
    display: flex;
    flex-direction: row;
    margin-top: 32px;

    &-count {
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-content: flex-start;
      flex: 1;
      font-size: 12px;
      line-height: 24px;

      &-all {
        color: $gray-dusty-light;
        margin-left: 12px;
      }

      &-new {
        color: $blue-shakespeare;
        margin-left: 5px;
      }
    }

    &-open {
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-content: flex-end;
      flex: 1;
      font-size: 12px;
      line-height: 24px;

      &-text {
        color: $gray-dusty-light;
        margin-right: 17px;
        cursor: pointer;
      }

      &-icon {
        margin-right: 17px;
        margin-top: 6px;
        cursor: pointer;

        &_opened {
          transform: rotate(180deg);
        }
      }
    }
  }
}
