@import '../../../styles/variables';

.notification-icon {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-left: 10px;
  .anticon {
    svg {
      display: inline-block;
      width: 50px;
      height: 50px;
    }
  }

}
