@import '../../../styles/variables';

.course-header {
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  background: url('../../../assets/images/course-nav-background.png');

  @media (min-width: $min-mobile) and (max-width: $mobile) {
    background-color: $green-sushi;
    background-size: 100%  556px;
  }

  &__title {
    position: absolute;
    width: 720px;
    height: 48px;
    margin: 24px 0 0 48px;
    font-size: 24px;
    line-height: 32px;
    color: $light;
  }

  &__nav {
    display: flex;
    max-width: 1100px;
    justify-content: space-around;
    align-self: flex-end;
    flex: 1;

    &_short {
      max-width: 370px;
    }

    @media (min-width: $min-mobile) and (max-width: $mobile) {
      display: none;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 24px;
    color: $gray-gull;
    text-decoration: none;
    padding-bottom: 9px;
    border-bottom: 4px solid transparent;

    &_active {
      font-weight: bold;
      color: $light;
      border-bottom: 4px solid $light;
    }
  }

  &__adaptive-nav {
    display: none;
  }

  @media (min-width: $min-mobile) and (max-width: $mobile) {
    width: 100%;
    height: 130px;

    &__adaptive {
      &-nav {
        display: flex;
        margin-left: 16px;
        margin-bottom: 13px;
        margin-top: 80px;
        z-index: 100;
      }

      &-block {
        display: flex;
        flex-direction: row;
        align-content: center;
        width: 142px;
        height: 25px;
        margin-top: 12px;

        &:focus {
          border: none;
          outline: none;
        }
      }

      &-arrow {
        display: flex;
        align-self: center;
        margin-left: 10px;
      }

      &-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $light;
      }

      &-link {
        font-size: 15px;
        line-height: 20px;
        color: $black;
        text-decoration: none;
      }

      &-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 85px;
        margin: 0;
        padding: 0;
      }

      &-item {
        width: 328px;
        height: 46px;
        background-color: $gray-alabaster;
        padding: 18px 0 0 24px;
        &:first-child {
          border-bottom: 1px $gray-gallery solid;
        }
      }

      &-first-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 328px;
        height: 46px;
        background-color: $gray-alabaster;
        padding: 18px 0 0 24px;
        &:first-child {
          border-bottom: 1px $gray-gallery solid;
        }

      }

      &-arrow-up {
        width: 16px;
        height: 16px;
        margin-right: 20px;
        transform: rotate(180deg);
      }
    }
    &__title {
      font-size: 24px;
      line-height: 32px;
      width: 276px;
      height: 64px;
      margin: 24px 0 0 16px;
    }
  }
}
