@import '../../../styles//variables';

.admin-item {
  display: flex;
  align-items: center;

  &__avatar {
    margin-right: 8px;
  }

  &__name,
  &__text {
    margin-right: 3%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: 1.333rem;
    line-height: 2.333rem;
  }

  &__name {
    max-width: 30%;
    min-width: 30%;
    color: $black;
  }

  &__text {
    flex-grow: 1;
    color: $gray-dove;
  }
}