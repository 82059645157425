@import '../../../styles/variables';

.input-field {
  width: 100%;
  height: 95px;

  &__input {
    margin-bottom: 3px;
    width: 100%;
    border: 0;
    outline: 0;
  }

  &__text {
    margin: 0;
    padding: 0;

    font-size: 1.15rem;
    color: $gray-dove;
    white-space: nowrap;
  }

  &__error {
    margin: 0;
    padding: 0;

    color: $red-flamingo;
  }

  &__title {
    margin: 0 0 4px 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  &__blue-star {
    margin: 0;
    margin-left: 3px;

    color: $blue-shakespeare
  }

  &__optional {
    font-size: 1.15rem;
    margin: 0 0 0 3px;
    color: $gray-silver;
  }
}
