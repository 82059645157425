@import '../../styles/variables';

.edit-profile-page {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__page-wrap {
    padding: 32px 15px;
    max-width: 662px;
    margin: 0 auto;
  }

  &__title {
    font-size: 20px;
    line-height: 23px;
    color: $black;
    margin-bottom: 9px;
  }

  &__separator {
    border: none;
    width: 100%;
    border-top: 1px solid $gray-mercury;
    margin-bottom: 32px;

    &_in-form {
      margin: 0 82px;
      width: calc(100% - 164px);

      @media (max-width: $mobile) {
        margin: 0 16px;
        width: calc(100% - 32px);
      }
    }
  }

  &__content {
    border: 1px solid $gray-mercury;
  }

  &__user-form {
    padding: 32px 82px 24px;

    @media (max-width: $mobile) {
      padding: 32px 16px 24px;
    }
  }

  &__reset-password,
  &__export-data,
  &__close-account {
    padding: 24px 82px;

    @media (max-width: $mobile) {
      padding: 24px 16px;
    }
  }


}
