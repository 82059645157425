@import '../../../styles/variables';

.work-time-select {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $gray-alabaster;
  border: 1px solid $gray-alto;
  padding: 6px 16px;
  box-sizing: border-box;

  &__title {
    font-size: 12px;
    line-height: 20px;
    color: $gray-boulder;
    margin-bottom: 3px;
  }

  &__value {
    font-size: 24px;
    line-height: 28px;
    color: $black;
  }

  &__open-btn {
    width: 10px;
    height: 10px;
    border: none;
    border-bottom: 2px solid $gray-tundora;
    border-right: 2px solid $gray-tundora;
    border-radius: 0;
    transform: rotate(45deg);
    position: absolute;
    right: 25px;
    top: 25px;
    padding: 0;
    outline: none;
    background-color: transparent;

    &_open {
      transform: rotate(-135deg);
      top: 28px;
    }
  }

  &__list {
    display: none;
    box-sizing: border-box;
    list-style-type: none;

    &_open {
      display: block;
      width: calc(100% + 2px);
      background-color: $gray-alabaster;
      border: 1px solid $gray-alto;
      border-top: none;
      padding: 6px 16px;
      position: absolute;
      z-index: 10;
      top: 99%;
      left: -1px;
      margin: 0;
    }
  }

  &__list-item-btn {
    text-align: left;
    width: 100%;
    padding: 10px 0;
    border: none;
    background-color: $gray-alabaster;
    font-size: 16px;
    line-height: 18px;
    color: $black;
    font-weight: 200;
    outline: none;

    &_active {
      font-weight: 400;
    }
  }
}