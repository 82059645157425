@import '../../../styles/variables';

.faq {
  max-width: 672px;
  padding: 0 16px;
  margin: 64px auto 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &__search {
    margin-bottom: 40px;
  }
}
