@import '../../../styles/variables';

.about-company {
  display: flex;
  padding: 32px;
  align-items: flex-start;

  @media (max-width: $mobile) {
    flex-direction: column;
    padding: 32px 16px;
  }

  &__logo {
    max-width: 250px;
    margin-right: 15px;

    @media (max-width: $mobile) {
      max-width: 100%;
      box-sizing: border-box;
      margin-right: 0;
      margin-bottom: 16px;
      align-self: center;
    }
  }

  &__text {
    color: $gray-mine-shaft;
    font-size: 20px;
    line-height: 28px;
    white-space: pre-line;
    flex: 1;
  }

  .public-DraftEditor-content[contenteditable=true] {
    -webkit-user-modify: unset !important;
  }
}