.swing {
  animation: swing 0.6s ease;
  animation-iteration-count: 1;
}

@keyframes swing {
  15% {
    transform: translateX(9px);
  }
  30% {
    transform: translateX(-9px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-6px);
  }
  65% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}
