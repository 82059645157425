@import '../../../styles/variables';

.horizontal-progress-bar {
  height: 4px;
  background-color: $green-sushi;
  display: flex;
  justify-content: flex-start;

  &__progress-line {
    height: 100%;
    background-color: $gray-alto;
  }
}