@import '../../../styles/variables';

.exam-view {
  width: 100%;
  max-width: 624px;
  padding: 24px 48px;

  &__title {
    font-size: 20px;
    line-height: 28px;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    color: $gray-dove;
  }

  &__atempt {
    background-color: $aqua-spring;
    padding: 10px 14px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.213333px;
  }

  &__footer-btn {
    display: flex;
    flex-direction: row-reverse;
    font-size: 16px;
    line-height: 28px;
    margin: 14px 0;
  }

  &__footer {
    display: flex;
    border-top: 1px solid $gray-alto;
    padding: 20px 0px;
    margin-top: 20px;
  }

  &__points-text {
    font-size: 14px;
    line-height: 20px;
    white-space: normal;
    margin-right: 8px;
    color: $gray-dusty-light;
    text-align: right;
  }

  &__big-points {
    font-size: 48px;
    line-height: 48px;
    color: $big-points-color;
  }

  &__button {
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
    color: $gray-mine-shaft;
  }

  &__icon {
    margin-left: 6px;
  }

  &__completed-title {
    font-size: 24px;
    line-height: 24px;
    color: $success-title-color;
    margin: 22px 0;
  }

  &__progress {
    height: 168px;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }

  &__background-layout {
    height: 168px;
    width: 96px;
    background-color: $gray-gallery-background;
    position: relative;
  }

  &__progress-result {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $green-sushi;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    &_error {
      background-color: $error-text-color;
    }
  }

  &__result-points {
    font-size: 28px;
    line-height: 48px;
    color: $light;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    &_error {
      color: black;
    }
  }

  &__score {
    font-size: 12px;
    line-height: 40px;
    color: #FFFFFF;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  &__max-score {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    width: 144px;
    position: absolute;
    left: 96px;

    &-title {
      font-size: 12px;
      text-align: right;
      color: rgba(0, 0, 0, 0.4);
      width: 100%;
      margin-top: -16px;

      &_left {
        text-align: left;
      }
    }

    &_left {
      left: -153px;
      width: 153px;
    }
  }

  &__failed-title {
    font-size: 24px;
    line-height: 24px;
    color: $error-text-color;;
    margin-top: 14px;
  }

  &__text {
    margin: 0;
  }
}